.body {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.radioIconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
