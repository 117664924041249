.faqListBox{
    width: 100%;
    flex-direction: column;
}

.card{
    color: unset;
    box-shadow: unset;
    border-radius: unset;
    background-color: unset;
    overflow: hidden;
}

.cardContent{
    padding: 16px;
}

.cKEditorContent{
 
}
