.numberedListBox{
    width: 100%;
    flex-direction: column;
}

.counterNumberHolder {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
}

.cKEditorContent{

}