.selectFormControl{
    width: 100%;
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    margin-bottom: 0;
    flex-direction: column;
    vertical-align: top;
}
