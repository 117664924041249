.styledButton {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center !important;
  background-size: cover !important;
}

.styledImage {
  width: 100%;
  height: 100%;
}

.flexBox {
  display: flex;
  justify-content: center;
}

.iconBox {
  width: auto;
  height: auto;
}

.cardsNavButton {
  .firstRow {
    flex-direction: column;
    justify-content: center;
  }

  .iconBox {
    width: auto;
  }
}
