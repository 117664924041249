.hiddenInput {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
}

.label {
  position: relative;
  cursor: pointer;
}

.holder {
  color: rgba(0, 0, 0, 0.54);
}
