.editorContent {
    margin-top: 20px;
}

.bulletedListBox{
    width:100%;
    flex-direction: column;
}

.listItemBox{
    display: flex;
    align-items: flex-start;
}