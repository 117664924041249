.root {
  min-height: 100px;
}

.video {
  position: relative;
  min-width: 100px;
  min-height: 100px;
  overflow: auto;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
