.root {
  text-align: center;
  font-weight: bold;
  line-height: 1em;
}

.number {
  min-width: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label {
  width: 100%;
  font-size: 16px;
}

.separator {
  align-items: center;
  min-width: 12px;
  justify-content: center;
  display: flex;
}
