.all {
  display: flex;
}

.desktopOnly {
  display: none !important;

  @media (min-width: 1280px) {
    display: flex !important;
  }
}

.mobileOnly {
  display: flex !important;

  @media (min-width: 1280px) {
    display: none !important;
  }
}
