.stepBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.formButtonBox {
  display: flex;
  width: 100%;
}

.purchaseForm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.purchaseFormTextField {
  margin-bottom: 0;
}

.purchaseFormButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}