.checkboxLabel{
    display: block;
    position: relative;
    padding-left: 32px;
    user-select: none;
}

.checkboxInput{
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
}

.checkboxSpan{
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
   
}
